import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import passwordReset from '../views/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
  { path: "*", redirect: "/login/login/1" },
  {
    path: '/login/:mode/:redirect/',
    name: 'Login',
    props: true,
    component: Login
  },
  {
    path: '/register/',
    name: 'Register',
    props: true,
    component: Register
  },
  {
    path: '/resetPassword/:id',
    name: 'passwordReset',
    props: true,
    component: passwordReset
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
