import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import config from '@/config'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            dark: Object.assign(config.dark, {error: "#F44336"}),
            light: Object.assign(config.light, {error: "#F44336"}),
        }
    },
    iconfont: 'md'
});
