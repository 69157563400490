<template>
  <v-card-text>
    <v-stepper v-model="form.stepper" style="box-shadow: none !important">
      <v-stepper-items>
        <v-stepper-content step="1" style="padding: 0 !important;">
          <v-form ref="emailForm" @submit.prevent="firstStep()">
            <v-text-field
                prepend-icon="mdi-email"
                v-model="form.createEmail"
                :rules="rules.emailRules"
                v-bind:label="$t('loginForm.email')"
                required
                type="email"
                data-cy="register-createEmail"
                autocomplete="email"
            ></v-text-field>
          </v-form>
          <v-btn color="primary" block @click="firstStep()" class="mt-2" data-cy="register-continue">
            Weiter
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2" style="padding: 0 !important;">
          <v-form ref="passForm" lazy @submit.prevent="register()">
            <v-text-field
                prepend-icon="mdi-lock"
                v-model="form.createPassword"
                :rules="rules.passRules"
                :type="form.showPassword ? 'text' : 'password'"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="form.showPassword = !form.showPassword"
                v-bind:label="$t('loginForm.password')"
                v-bind:hint="$t('registerForm.passwordHint')"
                required
                data-cy="register-createPassword"
                autocomplete="new-password"
            ></v-text-field>
            <v-text-field
                prepend-icon="mdi-lock"
                v-model="form.createPassword2"
                :rules="rules.passRules"
                :type="form.showPassword ? 'text' : 'password'"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="form.showPassword = !form.showPassword"
                v-bind:label="$t('registerForm.reEnterPassword')"
                v-bind:hint="$t('registerForm.passwordHint')"
                required
                data-cy="register-createPassword2"
                autocomplete="new-password"
            ></v-text-field>

            <p
                v-if="form.registerPasswordWarning"
                class="error--text"
            >
              {{ $t("registerForm.passwordsNotIdentical") }}
            </p>
            <v-btn
                class="mt-5"
                light
                large
                block
                type="submit"
                color="primary"
                :dark="isValid"
                :disabled="!isValid"
                @click="register(false)"
                data-cy="register-button"
            >{{
                $t($vuetify.breakpoint.smAndDown ? "registerForm.createAccountBreakpointMobile" : "registerForm.createAccount")
              }}
            </v-btn>
            <v-btn class="mt-2" color="primary" small text block @click="form.stepper--">Zurück</v-btn>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-btn
        block
        data-cy="login-button"
        class="mt-5 mb-8"
        text
        color="primary"
        @click="gotoLoginPage()"
    >{{ $t("loginForm.registerPage") }}
    </v-btn>
    <terms ref="terms" @close="register(true)"/>
  </v-card-text>
</template>

<script>
import Terms from "../modal/Terms";
import emailRegex from "../../util/emailRegex";

let vm
export default {
  name: "RegisterForm",
  components: {Terms},
  data: () => ({
    rules: {
      emailRules: [
        (v) => !!v || vm.$t("generalForms.email.required"),
        (v) => emailRegex.test(v) || vm.$t("generalForms.email.invalid"),
      ],
      passRules: [
        (v) => !!v || vm.$t("generalForms.pass.required"),
        (v) => v.length >= 6 || vm.$t("generalForms.pass.length"),
      ],
    },
    form: {
      stepper: 1,
      showPassword: false,
      createEmail: "",
      createPassword: "",
      createPassword2: "",
      registerPasswordWarning: false,
    }
  }),
  created() {
    vm = this
  },
  computed: {
    isValid() {
      return !(this.form.createEmail.length === 0 ||
          this.form.createPassword.length === 0 ||
          this.form.createPassword2.length === 0);
    },
  },
  methods: {
    firstStep() {
      if (this.$refs.emailForm.validate()) {
        this.form.stepper++;
      }
    },
    register(acceptedTOS) {
      if (this.$refs.passForm.validate()) {
        //check if both passwords are the same
        this.form.registerPasswordWarning = false;

        if (
            this.form.createPassword !== this.form.createPassword2
        ) {
          this.form.registerPasswordWarning = true;
        } else {
          if (!acceptedTOS) {
            this.$refs.terms.open();
            return;
          }

          this.$refs.terms.close();

          //both passwords are the same
          //send data to register page
          this.$router.push({
            name: "Register",
            params: {
              prepData: JSON.stringify({
                pwd: this.form.createPassword.trim(),
                email: this.form.createEmail.trim().toLowerCase(),
              }),
            },
          });
        }
      }
    },
    gotoLoginPage() {
      this.$router.push({
        name: "Login",
        params: {
          mode: "login",
          redirect: 1,
        },
      });
    }
  }
}
</script>

<style scoped>

</style>
