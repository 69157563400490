import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import staticDataZip from "../../assets/zip-de-at-ch-li-lu.min.json";

const state = {
  zipCodes: staticDataZip.zips,
};

const staticDataModule = {
  state,
  mutations,
  actions,
  getters,
};

export default staticDataModule;
