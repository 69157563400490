<template>
  <v-app>
    <alertComponent/>

    <v-container class="background fill-height flex" fluid>
      <div class="login-badges__container">
          <span title="onDispatch ist DSGVO Konform" style="min-width: 120px !important;">
            <img src="@/assets/dsgvo.png" width="128" alt="DSGVO Konform"/>
          </span>
        <span title="onDispatch wurde komplett in Deutschland gestaltet und entwickelt" class="mt-2 mr-8">
            <img src="@/assets/made-in-germany.png" width="175" alt="Designed, developed and made in Germany"/>
          </span>
        <span class="d-flex flex-column" title="onDispatch ist komplett SSL Verschlüsselt"
              style="min-width: 120px !important;">
            <v-icon color="green darken-2" size="64">mdi-lock-check</v-icon>
            <p class="text-center green--text text--darken-2 font-weight-medium text-sm-h5">SSL</p>
          </span>
      </div>
      <div class="mx-auto align-center justify-center mt-lg-12">
        <!-- Badges -->
        <v-card elevation="4" max-width="460" class="relative">
          <v-fade-transition>
            <v-img
                src="@/assets/onDispatch_banner.png"
                width="512"
                class="logo"
                v-if="$vuetify.breakpoint.name !== 'xs'"
            />
          </v-fade-transition>
          <v-card class="questions-dialog">
            <v-card-title>Du hast Fragen?</v-card-title>
            <v-card-subtitle>Dann melde dich bei uns unter:</v-card-subtitle>
            <v-list dense flat>
              <div v-ripple>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <a href="mailto:onDispatch Support <support@ondispatch.de>"><u>support@ondispatch.de</u></a>
                  </v-list-item-title>
                </v-list-item>
              </div>

              <div v-ripple>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <a href="tel:+49 931 73047510"><u>+49 931 73047510</u></a>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-card>

          <v-card-title>
            <p class="headline">
              {{
                isLoginPage ? $t("loginForm.login") : $t("loginForm.register")
              }}
            </p>
          </v-card-title>
          <v-card-subtitle>
            {{
              $t(
                  isLoginPage
                      ? "loginForm.headerMessageLogin"
                      : "registerForm.headerMessagePrimary"
              )
            }}
          </v-card-subtitle>

          <LoginForm v-if="isLoginPage"/>

          <RegisterForm v-else/>
        </v-card>
      </div>
    </v-container>

    <loginFooter/>
  </v-app>
</template>
<script>
import alertComponent from "../components/alertComponent.vue";
import loginFooter from "../components/loginFooter.vue";
import LoginForm from "../components/auth/LoginForm";
import RegisterForm from "../components/auth/RegisterForm";

export default {
  name: "Login",
  components: {alertComponent, loginFooter, RegisterForm, LoginForm},
  computed: {
    isLoginPage() {
      return this.$route.params.mode === "login";
    },
  },
  methods: {
    routerPush({name, params}) {
      if (this.$route.params.mode === params.mode) return;

      this.$router.push({
        name,
        params,
      });
    },
  },
  mounted() {
    if (
        this.$route.params.mode !== "login" &&
        this.$route.params.mode !== "register"
    ) {
      this.routerPush({
        name: "Login",
        params: {
          mode: "login",
          redirect: 1,
        },
      });
    }
  },
};
</script>
<style scoped>
.background {
  background-color: #f3f3fa;
  background-image: url("../assets/screenshot-dashboard-blurred.png");
  background-size: cover;
}

.logo {
  position: absolute;
  top: -128px;
  left: 50%;
  transform: translateX(-50%);
}

.login-badges__container {
  opacity: 0.7;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.relative {
  position: relative;
}

.questions-dialog {
  position: absolute;
  top: 0;
  right: -270px;
  width: 250px;
}

@media screen and (max-width: 900px) {
  .questions-dialog {
    display: none;
  }
}

@media screen and (max-height: 1000px) {
  .login-badges__container {
    display: none;
  }
}

</style>
