export default {
  light: {
    primary: "#1F517B",
    secondary: "#42AAE6",
    accent: "#8c9eff",
    error: "#BCD232",
    primaryConst: "#536dfe",
    secondaryConst: "#FF5C93",
    warning: "#FFC260",
    success: "#3CD4A0",
    successConst: "#3CD4A0",
    info: "#9013FE",
    greyBold: "#4a4a4a",
    greyBoldConst: "#4a4a4a",
    greyMedium: "#6e6e6e",
    greyTint: "#b9b9b9",
    default: "#e0e0e0"
  },
  dark: {
    primary: "#1F517B",
    secondary: "#42AAE6",
    accent: "#8c9eff",
    error: "#BCD232",
    primaryConst: "#536dfe",
    secondaryConst: "#FF5C93",
    success: "#3CD4A0",
    successConst: "#3CD4A0",
    greyMedium: "#bfbfbf",
    greyBold: "#d6d6d6",
    greyBoldConst: "#4a4a4a",
    greyTint: "#9b9b9b",
    default: "#e0e0e0"
  }
};
