import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import config from "./store/config/index.js";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import i18n from './i18n'

//setup base axios data
axios.defaults.baseURL = config.state.backendServer;
axios.defaults.headers.common["Content-Type"] = "application/json";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
