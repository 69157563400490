import Vue from 'vue'
import Vuex from 'vuex'
import config from "./config/index.js";
import auth from "./auth/index.js";
import staticData from "./staticData/index.js";

Vue.use(Vuex)

//display version as console.log
console.log("onDispatch Login " + config.state.version);

export default new Vuex.Store({
  modules: {
    config,
    auth,
    staticData
  }
});
