import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
    loginInfo: StoreUtil.state(),
    registerInfo: StoreUtil.state(),
    userInfo: StoreUtil.state(),
}

const loginModule = {
    state,
    mutations,
    actions,
    getters
}

export default loginModule;