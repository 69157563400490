<template>
  <div class="or__container">
    <span class="line"/>
    <v-card-subtitle>
      <slot>
        ODER
      </slot>
    </v-card-subtitle>
    <span class="line"/>
  </div>
</template>

<script>
export default {
  name: "OrComponent"
}
</script>

<style scoped>
div.or__container {
  width: 100%;
  display: flex;
  align-items: center;
}

span.line {
  flex: 1;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10%;
}
</style>
