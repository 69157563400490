<template>
  <v-row justify="center">
    <v-dialog v-model="visible" width="600px">
      <v-card>
        <v-card class="sticky-title" outlined>
          <v-card-title>
            <span class="headline">onDispatch <span v-if="$vuetify.breakpoint.smAndUp">GmbH</span> AGBs</span>
          </v-card-title>
        </v-card>

        <v-card-text class="max-height-content">
          <conditions/>
        </v-card-text>

        <v-card outlined class="sticky-footer">
          <v-card-actions>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-btn
                color="green darken-1"
                text
                :block="$vuetify.breakpoint.xs"
                :x-small="$vuetify.breakpoint.xs"
                data-cy="terms-accept-button"
                @click="handleClick"
            >{{ $t("terms.accept") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import conditions from "./Conditions.vue";

export default {
  name: "Terms",
  components: {conditions},
  data: () => ({
    visible: false
  }),
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    handleClick() {
      this.$emit("close");
    }
  }
}
</script>
<style>
.sticky-title {
  position: sticky;
  top: 0;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
}

.max-height-content {
  max-height: 700px;
  overflow-y: scroll;
}
</style>
