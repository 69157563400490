const mutations = {
  CREATE_SNACKBAR(state, payload) {
    state.snackbar.visible = true;
    state.snackbar.message = payload;
  },
  CREATE_ERROR(state, payload) {
    state.error.visible = true;
    state.error.message = payload;
  },
  CREATE_UNIVERSAL_ALERT(state, value) {
    state.universalAlert.visible = true;
    state.universalAlert.text = value.text;
    state.universalAlert.heading = value.heading;
    state.universalAlert.type = value.type;
  },
  CLOSE_UNIVERSAL_ALERT(state) {
    state.universalAlert.visible = false;
    state.universalAlert.text = null;
    state.universalAlert.heading = null;
    state.universalAlert.type = null;
  }
};

export default mutations;
