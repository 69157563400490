<template>
  <v-footer padless fixed color="primary">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in $t('footer.links')"
        :key="link.name"
        color="white"
        text
        rounded
        class="mt-1"
        target="_blank"
        :href="link.link"
      >
        {{ link.name }}
      </v-btn>
      <v-col class="py-2 text-center white--text" cols="12">
        &copy; {{ new Date().getFullYear() }} —
        <strong>{{ $t("footer.packageName") }}</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["version"])
  }
};
</script>
