<template>
  <v-form
      @submit.prevent="login()"
      ref="formRef"
  >
    <v-card-text>
      <v-text-field
          prepend-icon="mdi-email"
          :label="$t('loginForm.email')"
          v-model="form.email"
          :rules="rules.emailRules"
          data-cy="email"
          type="email"
          autocomplete="email"
      />
      <v-text-field
          prepend-icon="mdi-lock"
          v-bind:label="$t('loginForm.password')"
          v-model="form.password"
          :type="form.showPassword ? 'text' : 'password'"
          :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="form.showPassword = !form.showPassword"
          :rules="rules.passRules"
          data-cy="password"
          autocomplete="current-password"
      />
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
              type="submit"
              block
              :disabled="
                    (form.password.length < 6 ||
                    form.email.length === 0)
                  "
              color="primary"
              large
              :loading="loginStatusPending || form.loading"
              @click="login()"
              data-cy="login-btn"
          >{{ $t("loginForm.login") }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              class="mt-4"
              text
              color="primary"
              data-cy="register-button"
              block
              :small="$vuetify.breakpoint.smAndDown"
              @click="
                    routerPush({
                      name: 'Login',
                      params: { mode: 'register', redirect: 1 },
                    })
                  "
          >{{ $t("registerForm.loginPage") }}</v-btn>
        </v-col>
      </v-row>
      <or-component class="px-1"/>

      <v-row class="mb-2">
        <v-col>
          <v-btn
              block
              text
              color="primary"
              data-cy="login-reset-btn"
              @click="$refs.reset.open(form.email)"
          >{{ $t("loginForm.resetPassword") }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <reset-password-modal ref="reset" :email-rules="rules.emailRules"/>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import ResetPasswordModal from "../modal/ResetPasswordModal";
import OrComponent from "../OrComponent";
import emailRegex from "../../util/emailRegex";

let vm
export default {
  name: "LoginForm",
  components: {OrComponent, ResetPasswordModal},
  computed: {
    ...mapGetters(["snackbar", "baseRedirectServer", "loginStatusPending"]),
  },
  data: () => ({
    rules: {
      emailRules: [
        (v) => !!v || vm.$t("generalForms.email.required"),
        (v) => emailRegex.test(v) || vm.$t("generalForms.email.invalid"),
      ],
      passRules: [
        (v) => !!v || vm.$t("generalForms.pass.required"),
        (v) => v.length >= 6 || vm.$t("generalForms.pass.length"),
      ],
    },
    form: {
      showPassword: false,
      email: "",
      password: "",
      loading: false,
    }
  }),
  created() {
    vm = this
  },
  methods: {
    createRedirectLink() {
      //create redirect link in the following format:
      let href;
      if (this.$route.params.redirect != 1) {
        //use redirect link as base link
        href = new URL(decodeURIComponent(this.$route.params.redirect));
      } else {
        //use default baseRedirectServer
        href = new URL(this.baseRedirectServer);
      }

      href.searchParams.append("token", localStorage.getItem("token"));
      href.searchParams.append("formTypeParam", this.$route.params.mode);
      href.searchParams.append("redirectParam", this.$route.params.redirect);

      console.warn(href.toString());

      window.location.replace(href.toString());
    },
    login() {
      let vm = this;
      this.loading = true;
      if (this.$refs.formRef.validate()) {
        this.$store
            .dispatch("login", {
              email: this.form.email.trim().toLowerCase(),
              password: this.form.password.trim(),
            })
            .then((r) => {
              //create redirect link when login success otherwise redirect to log-in
              r
                  ? vm.createRedirectLink()
                  : vm.routerPush({
                    name: "Login",
                    params: {mode: "login", redirect: 1},
                  });
              vm.loading = false;
            });
      }
    },
    routerPush({name, params}) {
      this.$refs.formRef.resetValidation();

      if (this.$route.params.mode === params.mode) return;

      this.$router.push({
        name,
        params,
      });
    },
  }
}
</script>

<style scoped>

</style>
