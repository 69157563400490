import axios from "axios";

const actions = {
  login(context, loginData) {
    context.commit("SET_LOGIN_INFO");
    return axios
      .post("login", {
        email: loginData.email.trim().toLowerCase(),
        password: loginData.password.trim(),
      })
      .then((r) => {
        if (r.data.role === "AppUser") {
          context.commit(
            "CREATE_SNACKBAR",
            "Berechtigungsfehler - Fahrer Anmeldung nur per App möglich"
          );
          context.commit("SET_LOGIN_INFO", null);
          return false;
        } else {
          localStorage.setItem("token", r.data.token);
          context.commit("SET_LOGIN_INFO", r.data);
          return true;
        }
      })
      .catch((err) => {
        context.commit("SET_LOGIN_INFO", err);
        context.dispatch("triggerError", err);
        return false;
      });
  },
  register(context, registerData) {
    context.commit("SET_REGISTER_INFO");
    let val = registerData;
    //format data
    val.email ? (val.email = val.email.trim().toLowerCase()) : "";
    val.password ? (val.password = val.password.trim()) : "";
    return axios
      .post("register", val)
      .then(function (r) {
        context.commit("SET_REGISTER_INFO", r.data);
        return r.data;
      })
      .catch(function (e) {
        if (e.response.status === 400) {
          //react on email error status code
          context.commit("SET_REGISTER_INFO", e);
          context.dispatch("triggerUniversalAlert", {
            text: "Es gab ein Problem bei der Registrierung des Nutzers, bitte verwende eine andere Email.",
            heading: "Fehler - Registrierung fehlgeschlagen",
            type: "warning",
          });
        } else {
          //general error
          context.commit("SET_REGISTER_INFO", e);
          context.dispatch("triggerError", e);
        }
        return e;
      });
  },
  getUserInfo(context) {
    context.commit("SET_USER_INFO");
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get("info", config)
      .then(function (r) {
        context.commit("SET_USER_INFO", r.data);
        return r.data;
      })
      .catch(function (e) {
        context.commit("SET_USER_INFO", e);
        context.dispatch("triggerError", e);
      });
  },
  resetPassword(context, postData) {
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .post("reset", postData, config)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  setNewPassword(context, postData) {
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .post("reset/" + postData.id, postData, config)
      .then(function (r) {
        //dont expect answere for sec reasons
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
