<template>
  <v-row justify="center">
    <v-dialog
      v-model="visible"
      width="600px"
      @click:outside="close()"
      max-width="500"
    >
      <success-icon v-if="false" />

      <v-card outlined>
        <v-card-title>{{ $t("loginForm.resetPassword") }}</v-card-title>
        <v-divider class="mb-2"/>

        <v-card-text class="mt-5">
          <v-form ref="form" @submit.prevent="resetPassword" lazy-validation>
            <v-text-field
              outlined
              dense
              label="E-Mail"
              :prepend-inner-icon="
                $vuetify.breakpoint.xs ? undefined : 'mdi-at'
              "
              v-model="mail"
              :rules="emailRules"
            />
          </v-form>
        </v-card-text>
        <v-card-subtitle class="mt-n6">{{ $t("resetPasswordForm.resetPasswordDescription") }}</v-card-subtitle>

        <v-card-actions>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-btn
            color="red"
            :dark="mail.length !== 0"
            :disabled="mail.length === 0"
            :block="$vuetify.breakpoint.xs"
            data-cy="terms-accept-button"
            @click="resetPassword"
            >{{ $t("resetPasswordForm.button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleSuccess" max-width="500">
      <v-card outlined>
        <v-card-title
          >{{
            $vuetify.breakpoint.xsOnly
              ? $t("resetPasswordForm.successSmall")
              : $t("resetPasswordForm.success")
          }}
        </v-card-title>
        <br />
        <v-card-subtitle>{{
          $t("resetPasswordForm.successSecondLine")
        }}</v-card-subtitle>
        <v-card-text>
          <success-icon class="mx-auto" />
        </v-card-text>
        <v-card-actions>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-btn
            color="secondary"
            text
            :block="$vuetify.breakpoint.xs"
            data-cy="terms-accept-button"
            @click="close"
            >{{ $t("resetPasswordForm.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import successIcon from "./SuccessIcon.vue";

export default {
  name: "ResetPasswordModal",
  components: { successIcon },
  props: ["emailRules"],
  data: () => ({
    visible: false,
    visibleSuccess: false,
    mail: "",
  }),
  methods: {
    open(mail) {
      this.visible = true;
      //if email is already set, use it in form
      if (mail) {
        this.mail = mail;
      }
    },
    close() {
      this.visible = false;
      this.visibleSuccess = false;
    },
    resetPassword() {
      if (this.$refs.form.validate()) {
        //post reset password
        this.$store
          .dispatch("resetPassword", { email: this.mail })
          .then((r) => {
            console.warn(r);
            this.visible = false;
            this.visibleSuccess = true;
          });
      }
    },
  },
};
</script>
