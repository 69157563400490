import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  version: "v1.0.2",
  backendServer: "https://erp-dev-api.ondispatch.de/api/v1/",
  baseRedirectServer: "https://dev-my.ondispatch.de/",
  error: {
    visible: false,
    message: null
  },
  snackbar: {
    visible: false,
    message: null
  },
  universalAlert: {
    visible: false,
    text: null,
    heading: null,
    type: null
  }
};

const configModule = {
  state,
  mutations,
  actions,
  getters
};

export default configModule;
