<template>
  <div>
    <span class="headline">I. Geltungsbereich und Allgemeines</span>
    <p><b>1.</b> Die onDispatch GmbH (nachfolgend Anbieter genannt) erbringt sämtliche Leistungen ausschließlich
      auf
      Grundlage dieser Allgemeinen Geschäftsbedingungen ("AGB").</p>

    <p><b>2.</b> Abweichungen von diesen Geschäftsbedingungen sind nur wirksam, wenn sie schriftlich vom Anbieter
      bestätigt wurden. Der Anbieter ist berechtigt, diese allgemeinen Geschäftsbedingungen jederzeit unter
      Einhaltung einer angemessenen Ankündigungsfrist zu ändern. Nach Veröffentlichung einer Änderungsmitteilung
      besitzt der Kunde ein außerordentliches Kündigungsrecht. Die Änderungen gelten als genehmigt, wenn der Kunde
      nicht innerhalb von 14 Tagen den Änderungen schriftlich widerspricht.</p>

    <p><b>3.</b> Alle Preise gelten zzgl. gesetzl. Mehrwertsteuer (zurzeit der Erstellung dieser AGB 19%).</p>

    <p><b>4.</b> Dieses Angebot richtet sich nur an gewerbliche Kunden.</p>


    <p><b>3</b>.II. Vertragsgegenstand</p>
    <p><b>1.</b> Der Anbieter stellt dem Kunden die Software onDispatch auf Basis einer Miete zur Verfügung
      (Software as a
      Service). Der Kunde erhält darüber hinaus keine Rechte an der Serverhardware oder dem Programmcode. Nicht
      Gegenstand dieses Vertrags sind die Hard-und Software-Infrastruktur, die der Kunde benötigt um auf das
      Leistungsangebot des Anbieters zu zugreifen.</p>

    <p><b>2.</b> In der Regel ist die Software 24 Stunden an 365 Tagen verfügbar. Wartungsarbeiten werden nach
      Möglichkeit
      außerhalb von regulären Arbeitszeiten (08:00 bis 18:00 Uhr) vorgenommen. Außerhalb dieser Zeit ist ein
      Zugang
      i.d.R. möglich, wird aber nicht garantiert. In dringenden Fällen können auch in der Kernzeit (08:00 bis
      18:00
      Uhr) Wartungsarbeiten notwendig werden - z.B. wegen dringender Sicherheitsupdates.</p>

    <p><b>3.</b> Der Anbieter nimmt automatisch eine tägliche Sicherung des kompletten Datenbestands vor. Hierbei
      werden
      alle Dateien sowie alle sonstigen Daten und Einstellungen gesichert und i.d.R. für 3 Tage lang aufbewahrt.
    </p>

    <p><b>4.</b> Der Kunde bleibt Eigentümer der von Ihm hinterlegten Daten.</p>

    <p><b>5.</b> Der Anbieter stellt dem Kunden auf Anfrage eine aktuelle Datensicherung zur Verfügung. Die
      Berechnung
      erfolgt nach Aufwand gemäß der jeweils aktuellen Preisliste.</p>

    <p><b>6.</b> Das Recht einer Modifizierung oder Verbesserung der Leistung behält sich der Anbieter vor. Werden
      Dienstleistungen kostenlos bereitgestellt, so der Anbieter berechtigt diese fristlos und ohne Vorankündigung
      wieder einzustellen. Ein Minderungs-, Erstattungs- oder Schadensersatzanspruch kann dadurch nicht geltend
      gemacht werden. Bei einer grundlegenden Änderung des rechtlichen oder technischen Standards im Internet wird
      dem Anbieter erlaubt außerordentlich zu kündigen, wenn es dadurch für den Anbieter unzumutbar wird, ihre
      Leistungen ganz oder teilweise im Rahmen des Vertragszwecks zu erbringen.</p>

    <p><b>7.</b> Der Anbieter hat das Recht, sich zur Leistungserbringung jederzeit und in beliebigem Umfang
      Dritter zu
      bedienen.</p>

    <p><b>8.</b> Der Anbieter kann darüber hinaus seine Rechte und Pflichten aus diesem Vertrag auf einen oder
      mehrere
      Dritte übertragen ("Vertragsübernahme"). Der Anbieter hat dem Kunden die Vertragsübernahme mindestens vier
      Wochen vor dem Zeitpunkt der Übernahme mitzuteilen. Für den Fall der Vertragsübernahme steht dem Kunden das
      Recht zu, den Vertrag mit Wirkung zum Zeitpunkt der Vertragsübernahme zu kündigen.</p>


    <span class="headline">III. Laufzeit, Vertragsverlängerung und Kündigung</span>
    <p><b>1.</b> Der Vertrag über die Nutzung der angebotenen Leistungen kommt zustande, wenn ein vom Anbieter
      bevollmächtigter Vertreter den vom Kunden erteilten Auftrag annimmt. Die Annahme wird konkludent oder
      schriftlich durch die erste Erfüllungshandlung bestätigt. Soweit sich der Anbieter zur Erfüllung der
      vereinbarten Dienstleistung Dritter bedient, werden diese nicht Vertragspartner des Kunden. Der Anbieter ist
      berechtigt den Vertragsabschluss ohne Angabe von Gründen zu verweigern.</p>

    <p><b>2.</b> Die Laufzeit beträgt, falls nicht anders angegeben, zwölf Monate. Der Vertrag verlängert sich
      automatisch
      um den jeweiligen Abrechnungszeitraum, soweit der Vertrag nicht mit einer Frist von drei Monaten, zum Ende
      des
      jeweiligen Abrechnungszeitraumes gekündigt wird.</p>

    <p><b>3.</b> Unberührt bleibt das Recht beider Vertragsparteien zur fristlosen Kündigung aus wichtigem Grund.
      Ein
      wichtiger Grund für den Anbieter ist insbesondere dann gegeben, wenn mindestens einer der folgenden
      Sachverhalte vorliegt:</p>
    <p>2.1. der Kunde verstößt trotz Abmahnung schuldhaft gegen eine vertragliche Pflicht;</p>
    <p>2.2. der Kunde beseitigt trotz Abmahnung nicht innerhalb angemessener Frist eine Vertrags- oder
      Rechtsverletzung.</p>

    <p><b>4.</b> Ein Zahlungsrückstand von vierzehn Kalendertagen berechtigt den Anbieter zu einer fristlosen
      Kündigung.</p>

    <p><b>5.</b> Eine Abmahnung ist entbehrlich, wenn es sich um einen Verstoß handelt, der eine Fortsetzung des
      Vertrages
      für den Anbieter unzumutbar macht. Dies ist insbesondere der Fall bei strafbarer Ausspähung oder
      Manipulationen der Daten des Anbieters oder anderer Kunden des Anbieters durch den Kunden.</p>

    <p><b>6.</b> Eine ordentliche oder außerordentliche Kündigung bedarf zu ihrer Wirksamkeit der Schriftform.</p>

    <p><b>7.</b> Nach Beendigung des Vertragsverhältnisses ist der Anbieter zur Erbringung der vertraglichen
      Leistungen
      nicht mehr verpflichtet.</p>

    <p><b>8.</b> Nach Beendigung des Vertragsverhältnisses kann der Anbieter nach Ablauf von 14 Tagen sämtliche
      auf dem
      Webserver befindliche Daten des Kunden löschen. Die rechtzeitige Speicherung und Sicherung der Daten liegt
      daher in der Verantwortung des Kunden.</p>

    <span class="headline">IV. Verpflichtungen des Kunden</span>
    <p><b>1.</b> Der Kunde verpflichtet sich, die vom Anbieter zum Zwecke des Zugangs erhaltenen Passwörter streng
      geheim zu
      halten und den Anbieter unverzüglich zu informieren, sobald er davon Kenntnis erlangt, dass unbefugten
      Dritten
      das Passwort bekannt ist.</p>

    <p><b>2.</b> Der Kunde ist verpflichtet, dem Anbieter seinen vollständigen Namen und eine ladungsfähige
      Postanschrift
      (keine Postfach- oder sonstige anonyme Adresse), E-Mailadresse und Telefonnummer anzugeben. Der Kunde
      versichert, dass alle dem Anbieter mitgeteilten Daten richtig und vollständig sind. Der Kunde hat bei
      Änderungen, die Daten unverzüglich durch Mitteilung an den Anbieter per Post, Telefax oder E-Mail zu
      aktualisieren.</p>

    <span class="headline">V. Einschränkungen der Nutzung durch den Kunden, Einhaltung gesetzlicher Vorschriften, Rechte Dritter</span>
    <p><b>1.</b> Der Kunde hat sicherzustellen, dass die Nutzung von onDispatch den Server nicht übermäßig
      belastet. Der
      Anbieter hat das Recht, den Account des Kunden bei einer Überlastung vorrübergehend zu sperren.</p>

    <span class="headline">VI. Schnittstellen zu anderen Systemen</span>
    <p><b>1.</b> Allgemeines:</p>
    <p>1.1 Der Anbieter stellt dem Kunden verschiedene optionale Schnittstellen zu anderen Systemen zur Verfügung.
      Der Kunde ist hierbei selbst für die Einhaltung der rechtlichen Rahmenbedingungen, insbesondere des
      Datenschutzes, zuständig.</p>

    <p>1.2 Sofern eine Schnittstelle für den Kunden durch die gesetzlichen Rahmenbedingungen seines Landes nicht
      erlaubt ist, ist der Kunde nicht berechtigt die entsprechende Schnittstelle zu nutzen.</p>

    <p><b>2.</b> E-Mail-Versand, Verbot</p>
    <p>2.1 Der Anbieter hat das Recht, die Maximalgröße der zu versendenden E-Mails jeweils auf einen angemessenen
      Wert zu beschränken, zurzeit 10MB.</p>

    <p>2.2 Der Versand von E-Mails über onDispatch ist unzulässig, soweit es sich um einen massenhaften Versand
      von
      E-Mails an Empfänger ohne deren Einwilligung handelt und/oder es sich um ein Werbe-E-Mail handelt und eine
      Einwilligung des Empfängers nicht vorliegt obwohl diese erforderlich ist (insgesamt nachfolgend als "Spam"
      bezeichnet). Der Nachweis einer Einwilligung des jeweiligen Empfängers obliegt dem Kunden.</p>

    <p>2.3 Dem Kunden ist untersagt mehr als 250 E-Mails pro Stunde zu versenden.</p>


    <p><b>3.</b> SMS-Versand</p>
    <p>3.1 Sofern das Merkmal im gebuchten Tarif vorhanden ist, kann der Kunde SMS über onDispatch versenden
      lassen.</p>

    <p>3.2 Für die Nutzung muss ein kostenpflichtiger Vertrag mit einem der unterstützten Anbieter geschlossen
      werden.</p>

    <span class="headline">VII. Entgeltzahlung, Entgelterhöhung, Zahlungsverzug, Entgelterstattung</span>
    <p><b>1.</b> Die Höhe der vom Kunden an den Anbieter zu bezahlenden Entgelte und der jeweilige
      Abrechnungszeitraum
      ergeben sich aus der aktuellen Preisliste. Die nutzungsunabhängigen Entgelte werden monatlich im Voraus
      fällig, die nutzungsabhängigen Entgelte mit Rechnungsstellung.</p>

    <p><b>2.</b> Der Kunde erteilt dem Anbieter zur Zahlung der zu erbringenden Lesitungen ein SEPA-Lastschrift
      Mandat. Der
      Kunde hat für ausreichende Deckung des Kontos Sorge zu tragen. Erfolgt eine vom Kunden zu vertretende
      Rücklastschrift, ist der Kunde verpflichtet, dem Anbieter die anfallenden Bankgebühren zu erstatten. Daneben
      hat der Kunde dem Anbieter in diesen Fällen eine pauschale Bearbeitungsgebühr in Höhe von 20,00 EUR zu
      bezahlen.</p>

    <p><b>3.</b> Befindet sich der Kunde mit einer Zahlung mindestens sieben Tage in Verzug, ist der Anbieter
      berechtigt,
      seine Leistung zu verweigern. In der Regel geschieht dies durch die Sperrung des Accounts. Befindet sich der
      Kunde mit einer Zahlung mindestens 14 Tage in Verzug, ist der Anbieter berechtigt, das gesamte
      Vertragsverhältnis mit dem Kunden außerordentlich zu kündigen.</p>

    <p><b>4.</b> Der Anbieter ist berechtigt, die Entgelte auch ohne Änderung der Leistung angemessen zu erhöhen.
      In jedem
      Fall angemessen ist insoweit eine jährliche Erhöhung um 5%. Die Entgelterhöhung bedarf der Zustimmung des
      Kunden. Die Zustimmung gilt als erteilt, wenn der Kunde der Erhöhung nicht binnen eines Monats nach Zugang
      der
      Änderungsmitteilung widerspricht. Der Anbieter ist verpflichtet, den Kunden mit der Änderungsmitteilung auf
      die Folgen eines unterlassenen Widerspruchs hinzuweisen. Widerspricht der Kunde der Preiserhöhung, steht dem
      Anbieter ein Sonderkündigungsrecht zu.</p>

    <p><b>5.</b> Vorausbezahlte Entgelte werden dem Kunden erstattet, wenn der Vertrag vor Ablauf des
      Abrechnungszeitraums
      endet. Im Falle einer wirksamen außerordentlichen Kündigung durch den Anbieter hat dieser Anspruch auf
      Zahlung
      des Entgelts für die gesamte vereinbarte Dauer des Vertrages.</p>

    <span class="headline">VIII. Leistungsstörung</span>
    <p><b>1.</b> Für Leistungsstörungen ist der Anbieter nur verantwortlich soweit diese die von ihm zu
      erbringenden
      Leistungen betreffen.</p>

    <p><b>2.</b> Störungen hat der Anbieter im Rahmen der technischen und betrieblichen Möglichkeiten unverzüglich
      zu
      beseitigen. Der Kunde ist verpflichtet, dem Anbieter für ihn erkennbare Störungen unverzüglich anzuzeigen
      ("Störungsmeldung"). Erfolgt die Beseitigung der Störung nicht innerhalb eines angemessenen Zeitraums, hat
      der
      Kunde dem Anbieter eine angemessene Nachfrist zu setzen. Wird die Störung innerhalb dieser Nachfrist nicht
      beseitigt, hat der Kunde Anspruch auf Ersatz des ihm nachweislich entstandenen Schaden.</p>

    <p><b>3.</b> Wird die Funktionsfähigkeit des Webservers aufgrund nicht vertragsgemäßer Inhalte oder aufgrund
      einer über
      den vertraglich vorausgesetzten Gebrauch hinausgehende Nutzung beeinträchtigt, kann der Kunde hinsichtlich
      hierauf beruhender Störungen keine Rechte geltend machen. Im Falle höherer Gewalt ist der Anbieter von der
      Leistungspflicht befreit. Hierzu zählen insbesondere rechtmäßige Arbeitskampfmaßnahmen, auch in
      Drittbetrieben
      und behördliche Maßnahmen, soweit nicht vom Anbieter verschuldet.</p>

    <span class="headline">IX. Sperrung, Voraussetzungen und Aufhebung der Sperrung, Kostenerstattung</span>
    <p><b>1.</b> Nimmt der Anbieter eine Sperrung vor, so ist er zur Sperrung sämtlicher vertragsgegenständlichen
      Dienste
      und Leistungen berechtigt. Die Wahl der Sperrmaßnahme liegt insoweit im Ermessen des Anbieters. Der Anbieter
      wird jedoch die berechtigten Belange des Kunden berücksichtigen. Durch eine Sperrung wird der Kunde nicht
      von
      seiner Verpflichtung entbunden, die vereinbarten Entgelte zu entrichten. Der Anbieter genügt seinen
      Mitteilungspflichten, wenn er die jeweiligen Mitteilungen per E-Mail an die vom Kunden angegebene
      E-Mailadresse sendet. Für die Sperrung und für die Aufhebung der Sperrung kann der Anbieter jeweils ein
      pauschales Entgelt von 50,00 EUR berechnen ("Sperr- und Entsperrgebühr").</p>

    <p><b>2.</b> Liegt offensichtlich ein Verhalten des Kunden oder ein diesem zurechenbares Verhalten Dritter
      vor, das
      gegen geltendes deutsches Recht oder Rechte Dritter verstößt, kann der Anbieter eine Sperrung vornehmen. Der
      Anbieter setzt den Kunden hierüber in Kenntnis.</p>

    <p><b>3.</b> Soweit der Anbieter von Dritten oder von staatlichen Stellen wegen eines Verhaltens in Anspruch
      genommen
      wird, das den Anbieter zur Sperrung berechtigt, verpflichtet sich der Kunde, den Anbieter von allen
      Ansprüchen
      freizustellen und diejenigen Kosten zu tragen, die durch die Inanspruchnahme oder Beseitigung des
      rechtswidrigen Zustandes entstanden sind. Dies umfasst insbesondere auch die erforderlichen
      Rechtsverteidigungskosten des Anbieters.</p>

    <span class="headline">X. Datenschutz</span>
    <p><b>1.</b> Der Anbieter erhebt, verarbeitet und nutzt personenbezogene Daten des Kunden.</p>

    <p><b>2.</b> Dem Kunden ist bekannt, dass die auf dem Webserver gespeicherten Inhalte aus technischer Sicht
      vom Anbieter
      jederzeit eingesehen werden können.</p>

    <p><b>3.</b> Der Anbieter verpflichtet sich eine Erklärung zur Auftragsdatenverarbeitung mit dem Kunden zu
      schließen.</p>

    <span class="headline">XI. Gerichtstand, anwendbares Recht</span>
    <p><b>1.</b> Gerichtsstand für sämtliche Ansprüche aus den Vertragsbeziehungen zwischen den Vertragsparteien
      sich
      ergebenden Streitigkeiten, insbesondere über das Zustandekommen, die Abwicklung oder die Beendigung des
      Vertrages ist - soweit der Kunde Vollkaufmann, juristische Person des öffentlichen Rechts oder
      öffentlich-rechtliches Sondervermögen ist - Würzburg. Der Anbieter kann den Kunden wahlweise auch an dessen
      allgemeinem Gerichtsstand verklagen.</p>

    <p><b>2.</b> Für die vom Anbieter auf der Grundlage dieser AGB abgeschlossenen Verträge und für die hieraus
      folgenden
      Ansprüche, gleich welcher Art, gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss
      der Normen, die in eine andere Rechtsordnung verweisen und unter Ausschluss des Übereinkommens der Vereinten
      Nationen über Verträge über den internationalen Warenkauf (CISG*).</p>

    <span class="headline">XII. Schriftform und Salvatorische Klausel</span>
    <p><b>1.</b> Der Kunde erklärt mit Abgabe seiner Bestellung ausdrücklich, dass er gewerblicher Kunde, bzw.
      nicht als
      Verbraucher bestellt. Der Anbieter weist auf die mögliche Strafbarkeit einer Falschangabe hiermit hin.</p>

    <p><b>2.</b> Die Vertragsparteien vereinbaren, dass soweit in vertraglichen Regelungen zwischen den Parteien
      Schriftform
      vorgesehen ist, diese durch E-Mail, gewahrt wird.</p>

    <p><b>3.</b> Sollten Bestimmungen dieser AGB und/oder des Vertrages unwirksam sein oder werden, so berührt
      dies die
      Wirksamkeit der übrigen Bestimmungen nicht. Die Vertragsparteien verpflichten sich, anstelle einer
      unwirksamen
      Bestimmung eine gültige Vereinbarung zu treffen, deren wirtschaftlicher Erfolg dem der unwirksamen so weit
      wie
      möglich nahekommt.</p>

    <p class="subtitle-1">
      <v-icon>history</v-icon>
      April 2020
    </p>
  </div>
</template>
<script>
export default {
  name: "Conditions"
}
</script>
