<template>
  <v-app>
    <alertComponent />

    <v-container class="background fill-height flex" fluid>
      <div class="mx-auto align-center justify-center">
        <v-card elevation="4" max-width="435" min-width="300" class="yee">
          <v-fade-transition>
            <v-img
              src="@/assets/onDispatch_banner.png"
              width="512"
              class="logo"
              v-if="$vuetify.breakpoint.name !== 'xs'"
            />
          </v-fade-transition>

          <v-card-title>
            <p class="headline">
              {{ $t("loginForm.newPassword") }}
            </p>
          </v-card-title>
          <v-card-subtitle>
            {{ $t("registerForm.headerMessagePasswordReset") }}
          </v-card-subtitle>

          <v-form @submit.prevent="newPassword()" ref="form" lazy-validation>
            <!-- Registration form-->
            <v-card-text>
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="setNewPassword.createPassword"
                :rules="passRules"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-bind:label="$t('loginForm.password')"
                v-bind:hint="$t('registerForm.passwordHint')"
                required
                data-cy="setNewPassword-createPassword"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="setNewPassword.createPassword2"
                :rules="passRules"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-bind:label="$t('registerForm.reEnterPassword')"
                v-bind:hint="$t('registerForm.passwordHint')"
                required
                data-cy="setNewPassword-createPassword2"
              ></v-text-field>

              <p
                v-if="setNewPassword.registerPasswordWarning"
                class="error--text"
              >
                {{ $t("registerForm.passwordsNotIdentical") }}
              </p>
              <br />
              <v-btn
                light
                large
                block
                :disabled="
                  setNewPassword.createPassword !=
                    setNewPassword.createPassword2 ||
                  setNewPassword.createPassword.length === 0 ||
                  setNewPassword.createPassword2.length === 0
                "
                :loading="loading"
                color="primary"
                @click="newPassword()"
                data-cy="setNewPassword-btn"
                >{{ $t("loginForm.submitNewPassword") }}</v-btn
              >
            </v-card-text>
          </v-form>
        </v-card>
      </div>
    </v-container>

    <loginFooter />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import loginFooter from "../components/loginFooter.vue";
import alertComponent from "../components/alertComponent.vue";

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      passRules: [
        (v) => !!v || this.$t("generalForms.pass.required"),
        (v) => v.length >= 6 || this.$t("generalForms.pass.length"),
      ],
      setNewPassword: {
        createPassword: "",
        createPassword2: "",
        registerPasswordWarning: false,
      },
      resetPasswordItem: {
        password: "",
        id: "",
      },
      loading: false,
    };
  },
  components: { loginFooter, alertComponent },
  methods: {
    newPassword() {
      let vm = this;
      vm.loading = true;
      if (this.$refs.form.validate()) {
        //trim data
        this.resetPasswordItem.password =
          this.setNewPassword.createPassword.trim();
        this.resetPasswordItem.id = this.$route.params.id;
        this.$store
          .dispatch("setNewPassword", this.resetPasswordItem)
          .then(() => {
            vm.$router.push({
              name: "Login",
              params: { mode: "login", redirect: "1" },
            });
            vm.loading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["snackbar", "baseRedirectServer", "loginStatusPending"]),
  },
};
</script>
<style scoped>
.background {
  background-color: #f3f3fa;
}

.logo {
  position: absolute;
  top: -128px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
