<template>
  <v-app>
    <alertComponent />
    <v-row no-gutters>
      <img
        v-if="$vuetify.breakpoint.mdAndDown"
        alt="construction site"
        src="@/assets/construction_271487904_2_25f.jpeg"
        class="background-image"
      />

      <v-col cols="12" lg="6" md="12" xl="8">
        <div v-if="$vuetify.breakpoint.lgAndUp" class="half-width">
          <img
            alt="construction site"
            draggable="false"
            src="@/assets/construction_271487904_2_25f.jpeg"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="6" md="12" xl="4">
        <v-card
          :class="{
            'horizontal-center-non-flex': $vuetify.breakpoint.lgAndUp,
            'max-width mx-auto': $vuetify.breakpoint.mdAndDown,
          }"
          :elevation="$vuetify.breakpoint.mdAndDown ? 4 : 0"
        >
          <v-card-title class="text-h4 mb-5"> onDispatch.de</v-card-title>
          <v-card-subtitle>
            {{ $t("registerDataForm.subtitle") }}
          </v-card-subtitle>
          <v-card-text class="pa-5">
            <h3 class="greyBold--text">
              {{ $t("registerDataForm.heading") }}
            </h3>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mt-1">
                <v-col cols="12" sm="6" md="7">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="registerItem.client.name"
                    v-bind:label="$t('registerDataForm.companyName')"
                    data-cy="client-name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row class="mt-3">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="registerItem.user.name"
                    :rules="requiredRule"
                    v-bind:label="$t('registerDataForm.name')"
                    data-cy="user-name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="registerItem.user.email"
                    :rules="requiredRule"
                    v-bind:label="$t('registerDataForm.email')"
                    data-cy="user-email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="registerItem.client.companyData.telephone"
                    :rules="requiredRule"
                    v-bind:label="$t('registerDataForm.phone')"
                    type="tel"
                    autocomplete="tel"
                    data-cy="company-phone"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-3">
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    v-model="registerItem.client.companyData.address.street"
                    :rules="requiredRule"
                    v-bind:label="$t('registerDataForm.street')"
                    data-cy="street"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model.number="
                      registerItem.client.companyData.address.streetNumber
                    "
                    :rules="requiredRule"
                    type="Number"
                    v-bind:label="$t('registerDataForm.streetNumber')"
                    data-cy="streetNumber"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="8">
                  <v-autocomplete
                    v-bind:label="$t('registerDataForm.placeZipComboField')"
                    data-cy="preFormatAddress"
                    v-model="preFormatAddress"
                    :items="zipMap"
                    :rules="requiredRule"
                    item-text="Description"
                    item-value="addressData"
                    v-bind:no-data-text="
                      $t('registerDataForm.placeZipComboFieldNoData')
                    "
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              :block="$vuetify.breakpoint.xs"
              color="primary"
              class="button-shadow"
              :loading="registerStatusPending || loading"
              data-cy="register"
              @click="register"
            >
              {{
                $vuetify.breakpoint.xs
                  ? $t("registerDataForm.registerNowBreakpointMobile")
                  : $t("registerDataForm.registerNow")
              }}
              <v-icon right dark>arrow_forward</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions class="mb-2">
            <v-spacer />
            <v-btn color="primary" text small @click="$router.go(-1)">
              Zurück
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import alertComponent from "../components/alertComponent.vue";

export default {
  name: "Register-Dialog",
  data() {
    return {
      valid: true,
      requiredRule: [(v) => !!v || this.$t("generalForms.required")],
      registerItem: {
        client: {
          name: null,
          license: "onds-prem",
          companyData: {
            telephone: null,
            address: {
              postalCode: null,
              streetNumber: null,
              street: null,
              city: null,
              country: null,
            },
          },
        },
        user: {
          name: null,
          email: null,
          password: null,
        },
      },
      loading: false,
    };
  },
  components: { alertComponent },
  methods: {
    register() {
      let vm = this;
      vm.loading = true;
      if (this.$refs.form.validate()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "registered",
        });
        //trim data
        this.registerItem.user.email = this.registerItem.user.email.trim();
        this.registerItem.user.password =
          this.registerItem.user.password.trim();
        this.$store.dispatch("register", this.registerItem).then((r) => {
          if (r.message === "success") {
            //login in user when registered
            vm.$store
              .dispatch("login", {
                email: vm.registerItem.user.email.trim(),
                password: vm.registerItem.user.password.trim(),
              })
              .then((r) => {
                //create redirect link when login success otherwise redirect to login
                if (r) {
                  vm.createRedirectLink();
                } else {
                  vm.$router.push({
                    name: "Login",
                    params: { mode: "login", redirect: "1" },
                  });
                }
                vm.loading = false;
              });
          }
          vm.loading = false;
        });
      } else {
        //disable loading when validation not successfull
        vm.loading = false;
      }
    },

    createRedirectLink() {
      //create redirect link in the following format:
      let href = new URL(this.baseRedirectServer);

      href.searchParams.append("token", localStorage.getItem("token"));
      href.searchParams.append("formTypeParam", "register");
      href.searchParams.append(
        "redirectParam",
        encodeURIComponent(window.location.href)
      );

      window.location.replace(href.toString());
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters(["zipCodes", "registerStatusPending", "baseRedirectServer"]),
    zipMap() {
      return this.zipCodes.map((zip) => {
        const description =
          zip.zipcode +
          " - " +
          zip.place +
          " - " +
          zip.state +
          "-" +
          zip.country_code;
        let addressData = zip;

        if (addressData != null) {
          return Object.assign({}, zip, {
            Description: description,
            addressData: addressData,
          });
        }
      });
    },
    preFormatAddress: {
      get() {
        return "";
      },
      set(val) {
        this.registerItem.client.companyData.address.city = val.place;
        this.registerItem.client.companyData.address.postalCode = val.zipcode;
        this.registerItem.client.companyData.address.country = val.country_code;
      },
    },
  },
  mounted() {
    //check if prop data is available (if prop object is available)
    if (
      this.$route.params &&
      Object.keys(this.$route.params).length === 0 &&
      this.$route.params.constructor === Object
    ) {
      //missing props
      this.$router.push("/login/register/1");
    } else {
      //if object setup data
      //data from 1st page
      const registerData = JSON.parse(this.$route.params.prepData);

      this.registerItem.user.password = registerData.pwd;
      this.registerItem.user.email = registerData.email;
    }
  },
};
</script>

<style>
.half-width {
  height: 100vh;
}

.half-width img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.max-width {
  max-width: 700px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.horizontal-center-non-flex {
  top: 50%;
  transform: translateY(-50%);
}
</style>
