import { StoreUtil } from "../utils";

const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = StoreUtil.updateState(state.userInfo, payload);
  },
  SET_LOGIN_INFO(state, payload) {
    state.loginInfo = StoreUtil.updateState(state.loginInfo, payload);
  },
  SET_REGISTER_INFO(state, payload) {
    state.registerInfo = StoreUtil.updateState(state.registerInfo, payload);
  }
};

export default mutations;
